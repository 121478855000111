<template>
  <div id="app-base-subwindow-ipc">
    <div id="video_box">
      <video id="vplayer_start" ref="vplayer_start"></video>
      <video id="vplayer" ref="vplayer"></video>
      <video id="insert1" ref="insert1"></video>
      <video id="insert2" ref="insert2"></video>
    </div>
  </div>
</template>
<script>
import { ipcApiRoute, specialIpcRoute, ipcRenderer } from "@/api/main";
import { shuffle } from "@/utils/tools";
export default {
  data() {
    return {
      vplayer_start: null,
      vplayer: null,
      active: "vplayer_start",
      activeNode: null,
      insertList: [],
      // vlist: [require("@/assets/video/video1.mp4"), require("@/assets/video/video2.mp4"), require("@/assets/video/video3.mp4")],
      vlist: [],
      baselist: [],
      arrHuDong: [],
      mainWCid: 0,
      israndom: false,
      curr: 1,
      Icurr: 0,
      myQueue: {},
      saveActive: {
        resule: false,
        value: null,
      },
      hideNode: null,
      hideVideoNode: null,
      nextVideoNode: null,
      setVd1src: null,
      setVd2src: null,
      vd1end: null,
      vdend2: null,
      setInsertVd1src: null,
      setInsertVd2src: null,
      inserVd1End: null,
      inserVd2End: null,
      isAsync: false,
      liveInfo: {},
      localPath: "",
      isPlay: false,
      isHudong: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log("token:", localStorage.getItem("token"));
      // 监听主窗口发来的消息
      this.$ipc.removeAllListeners(specialIpcRoute.window1ToWindow2);
      this.$ipc.on(specialIpcRoute.window1ToWindow2, (event, arg) => {
        console.log("arg:", arg);
        // 主窗口匹配到关键词视频
        if (arg.type == "insertVideo") {
          this.insertList = [];
          this.Icurr = 0;
          let str = `${this.localPath}\\${arg.saveName}`;
          this.insertList.push(str);

          this.isPlay = true;
          this.addSrc();
        } else {
          this.liveInfo = arg.liveInfo;
          this.localPath = arg.path;
          this.israndom = arg.israndom;
          if (this.liveInfo.livelist.length == 1) {
            this.liveInfo.livelist.push(this.liveInfo.livelist[0]);
          }
          this.liveInfo.livelist.map((i) => {
            let str = ` ${this.localPath}\\${i.saveName}`;
            this.vlist.push(str);
          });
          this.baselist = this.vlist;
          if (this.israndom) {
            this.vlist = shuffle(this.vlist);
          }
          // console.log(this.vlist);
          this.$refs.vplayer_start.src = this.vlist[0];
          this.loadvideo();
        }
      });
    },
    countdown(times) {
      console.log(111);
      setTimeout(() => {
        this.sendTosubWindow();
        this.isPlay = false;
      }, (times + 1) * 1000);
    },
    hidev() {
      this.$refs.vplayer_start.style.visibility = "hidden";
      this.$refs.vplayer.style.visibility = "hidden";
      this.$refs.insert1.style.visibility = "hidden";
      this.$refs.insert2.style.visibility = "hidden";
    },
    loadvideo() {
      //原视频组
      this.setVd1src = (e) => {
        console.log("setVd1src", this.vplayer.duration);
        this.setNextSrc(this.vplayer_start, "vplayer");
        // this.countdown(this.vplayer.duration);
      };
      this.setVd2src = (e) => {
        console.log("setVd2src", this.vplayer_start.duration);
        this.setNextSrc(this.vplayer, "vplayer_start");
        // this.countdown(this.vplayer_start.duration);
      };
      this.vd1end = (e) => {
        console.log("vd1end");
        e.target.pause();
        e.target.style.visibility = "hidden";
        e.target.style.opacity = 0;
        // this.hidev()
        this.playNext(this.vplayer);
      };
      this.vdend2 = (e) => {
        console.log("vdend2");
        e.target.pause();
        e.target.style.visibility = "hidden";
        e.target.style.opacity = 0;
        // this.hidev()
        this.playNext(this.vplayer_start);
      };
      //插入视频组
      this.setInsertVd1src = (e) => {
        console.log("setInsertVd1src");
        this.setNextSrc(this.$refs.insert2, "insert1");
        this.countdown(this.$refs.insert1.duration);
      };
      this.setInsertVd2src = (e) => {
        console.log("setInsertVd2src");
        this.setNextSrc(this.$refs.insert1, "insert2");
      };
      this.inserVd1End = (e) => {
        console.log("inserVd1End");
        e.target.pause();
        e.target.style.visibility = "hidden";
        e.target.style.opacity = 0;
        // this.hidev()
        this.playNext(this.$refs.insert2);
      };
      this.inserVd2End = (e) => {
        console.log("insertEnd2");
        e.target.pause();
        e.target.style.visibility = "hidden";
        e.target.style.opacity = 0;
        // this.hidev()
        this.playNext(this.$refs.insert1);
        this.countdown(this.$refs.insert1.duration);
      };
      this.setVideo();
    },
    setVideo() {
      this.vplayer_start = this.$refs.vplayer_start;
      this.vplayer = this.$refs.vplayer;

      // 播放第一个视频
      this.vplayer_start.style.visibility = "visible";
      this.vplayer_start.play();

      this.vplayer_start.addEventListener("play", this.setVd2src);
      this.vplayer.addEventListener("play", this.setVd1src);
      this.vplayer_start.addEventListener("ended", this.vd1end);
      this.vplayer.addEventListener("ended", this.vdend2);
    },
    addSrc() {
      console.log("active", this.active);
      // if (!this.isPlay) {
      //   return;
      // }
      switch (this.active) {
        case "vplayer_start":
          this.curr--;
          console.log("从第一个视频插入", "vplayer_start");
          this.vplayer_start.removeEventListener("ended", this.vd1end); //暂停原队列 vplayer
          this.setSrc(this.$refs.insert1, true); //设置 insert1 播放资源
          this.vplayer_start.addEventListener("ended", this.inserVd2End); //插入 insert1
          // 设置原队列规则
          this.isAsync = true;
          this.myQueue = {
            event: this.vd1end,
            eventName: "ended",
            vd: this.vplayer_start,
            removeVd: this.vplayer_start,
            insert: this.inserVd2End,
          };

          this.$refs.insert1.addEventListener("play", this.setVd2src); //设置原队列 vplayer 播放资源
          this.$refs.insert1.addEventListener("ended", this.vd1end); //继续原队列 vplayer

          break;
        case "vplayer":
          this.curr--;
          console.log("从第er个视频插入", "vplayer");
          this.vplayer.removeEventListener("ended", this.vdend2); //暂停原队列 vplayer_start
          this.setSrc(this.$refs.insert1, true); //设置 insert1 播放资源
          this.vplayer.addEventListener("ended", this.inserVd2End); //插入 insert1

          // 设置原队列规则
          this.isAsync = true;
          this.myQueue = {
            event: this.vdend2,
            eventName: "ended",
            vd: this.vplayer,
            removeVd: this.vplayer,
            insert: this.inserVd2End,
            active: "vplayer",
          };
          this.$refs.insert1.addEventListener("play", this.setVd1src); //设置原队列 vplayer_start 播放资源
          this.$refs.insert1.addEventListener("ended", this.vdend2); //继续原队列 vplayer_start
          break;
      }
    },
    setNextSrc(vd, active) {
      console.log("v-play", this.active);
      this.active = active;
      this.setSrc(vd, active == "insert1" || active == "insert2");
    },
    playNext(vd) {
      vd.style.visibility = "visible";
      vd.style.opacity = 1;

      this.play(vd, this.active == "insert1" || this.active == "insert2");
      if (this.isAsync) {
        console.log('移除插入视频的监听',this.myQueue);
        this.myQueue.removeVd.removeEventListener(this.myQueue.eventName, this.myQueue.insert);
        this.myQueue.vd.addEventListener(this.myQueue.eventName, this.myQueue.event);
        this.myQueue = {};
        this.isAsync = false;
      }
    },
    play(vd, isInsert) {
      vd.play();
      console.log(this.Icurr, this.curr, "idx");
      if (isInsert) {
        // this.Icurr++;
        if (this.Icurr >= this.insertList.length) {
          console.log("重置");
          this.Icurr = 0; //重新循环播放
        }
      } else {
        this.curr++;
        if (this.curr >= this.vlist.length) {
          console.log("重置");
          // this.curr = 0; //重新循环播放
          if (this.israndom) {
            this.baselist = shuffle(this.baselist);
          }
          this.vlist.push(...this.baselist);
        }
      }
    },
    setSrc(vd, isInsert) {
      if (isInsert) {
        // console.log('setInsertSrc');
        console.log(this.insertList, this.Icurr, "arr");
        vd.src = this.insertList[this.Icurr];
        // console.log(vd.src);
      } else {
        console.log("setsrc");
        vd.src = this.vlist[this.curr];
        console.log(vd.src);
      }
    },
    sendTosubWindow() {
      // 获取主窗口id
      this.$ipc.invoke(ipcApiRoute.getWCid, "main").then((id) => {
        this.mainWCid = id;
        this.$ipc.sendTo(this.mainWCid, specialIpcRoute.window2ToWindow1, { msg: "窗口2 通过 sendTo 给主窗口发送消息" });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.vc-toggle .vc-panel {
  bottom: 0;
  min-height: 65% !important;
}

#video_box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

video {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
}

#app-base-subwindow-ipc {
  padding: 0px 10px;
  text-align: left;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;

  .one-block-1 {
    font-size: 16px;
    padding-top: 10px;
  }

  .one-block-2 {
    padding-top: 10px;
  }
}
</style>
